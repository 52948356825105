import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SeoComponent from '../components/SeoComponent';
import LayoutComponent from '../components/LayoutComponent';

const Home = props => {

  const data = props.data;

  return (
    <LayoutComponent bodyClass="page-home">
      <SeoComponent title={"Enkle finansråd"} />
      <Helmet>
        <meta
          name="description"
          content="Enkle finansråd"
        />
      </Helmet>
      <div className="container py-4">
        <div className="splash">
          <div>
            <h1>Sammenlign kredittkort og forbrukslån</h1>
          </div>
          <img className="intro-image pl-4 pt-2" src={data.data.frontmatter.image} />
        </div>
        <p className="d-flex justify-content-end md-block py-4">
            <div dangerouslySetInnerHTML={{ __html: data.data.html }} />
        </p>
        <ul className="auto-grid">
          {data.allToolsJson.edges.map(({ node }) => (
            <div className="card w-100" style={{width: "18rem"}}>
                <div className="card-body">
                  <Link to={node.link} className="card-title bold">{node.name}</Link>
                  <p className="card-text">{node.beskrivelse}</p>
                </div>
            </div>
          ))}
        </ul>
      </div>
    </LayoutComponent>
  );
};

export const query = graphql`
  query IndexQuery {
    data: markdownRemark(fileAbsolutePath: {regex: "/(index.md)/"}) {
              id
              html
              frontmatter {
                image
              }
            }
    allToolsJson {
      edges {
        node {
          name
          beskrivelse
          link
        }
      }
    }
  }
`;

export default Home;
